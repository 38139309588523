import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { currency } from "../../../../dynamicController";
import NewsSlider from "../dashboard/NewsSlider";

const CleanproTransaction = ({ user, changeNav, filter, selected, setSelected, transactions, reloads, transfers, points, renderDate, subscriptionHistory }) => {

    const [trans, setTrans] = useState(filter ? true : false);
    const [allTrans, setAllTrans] = useState([]);

    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        let allTransactionHistory = []
        for (let i = 0; i < transactions.length; i++) {
            allTransactionHistory.push({ ...transactions[i], transactionType: "Transaction" })
        }

        for (let i = 0; i < reloads.length; i++) {
            allTransactionHistory.push({ ...reloads[i], transactionType: "Reload" })
        }

        for (let i = 0; i < points.length; i++) {
            allTransactionHistory.push({ ...points[i], transactionType: "Point" })
        }

        for (let i = 0; i < subscriptionHistory.length; i++) {
            allTransactionHistory.push({ ...subscriptionHistory[i], transactionType: "Subscription" })
        }

        setAllTrans(allTransactionHistory)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div id="user-header">
                <h5>
                    <strong onClick={(e) => changeNav("stamp")}><i className="bi bi-chevron-left me-3"></i>{t("Inbox")}</strong>
                </h5>
            </div>

            <div id="user-body">
                <div className="pt-3 px-2 d-flex justify-content-between" style={{ backgroundColor: "white" }}>
                    <div
                        id="transaction-news"
                        onClick={() => setTrans(false)}
                        style={{ width: "45%" }}
                        className={`align-center no-wrap cursor-pointer ${!trans ? "active-tab" : "inactive-tab"}`}
                    >
                        {t("News")}
                    </div>
                    <div
                        id="transaction-activities"
                        onClick={() => setTrans(true)}
                        style={{ width: "50%" }}
                        className={`align-center no-wrap cursor-pointer ${trans ? "active-tab" : "inactive-tab"}`}
                    >
                        {t("Activities")}
                    </div>
                </div>

                {
                    trans ? (
                        <div>
                            <div
                                style={{ borderTop: "2px solid #eee", alignItems: "center", backgroundColor: "white" }}
                                className="mt-1 d-flex justify-content-between px-4"
                            >
                                <p style={{ fontSize: "12px" }}>{t("Up to 3 months history")}</p>
                                <select
                                    value={selected}
                                    onChange={(e) => setSelected(e.target.value)}
                                    className="d-block trans-select"
                                >
                                    <option value="Selected" hidden>
                                        {t("Filter")}
                                    </option>
                                    <option value="All">{t("All Transactions")}</option>
                                    <option value="Transaction">{t("Transactions")}</option>
                                    {user.tokenSetting && user.reload ? <option value="Reload">{t("Topup")}</option> : null}
                                    {/* {user.tokenSetting && user.transfer ? <option value="Transfer">{t("Transfer")}</option> : null} */}
                                    {user.loyalty_point ? <option value="Point">{user.loyalty_point_name}</option> : null}
                                    <option value="Subscription">{t("Laundry Pass")}</option>
                                </select>
                            </div>

                            <div className="pt-3">
                                {
                                    selected === "All" ? (
                                        allTrans.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                            .map(transaction => {
                                                if (transaction.transactionType === "Transaction") {
                                                    return (
                                                        <div
                                                            onClick={() => history.push("user/trans/details", { transaction })}
                                                            className="px-3"
                                                            key={transaction.id}
                                                        >
                                                            <div
                                                                style={{ padding: "20px 10px" }}
                                                                className="info-container mb-3 d-flex box-shadow-card"
                                                            >
                                                                <div style={{ width: "75%" }} className="text-right">
                                                                    {
                                                                        transaction.transaction_type === "Package"
                                                                            ? <h5 className="font-weight-bold">{t("Drop n Go Service")}</h5>
                                                                            : <h5 className="font-weight-bold">{t(transaction.machine_type)} {transaction.machine_name}</h5>
                                                                    }
                                                                    <p className="small-t">
                                                                        {renderDate(transaction.createdAt, true)}, {new Date(transaction.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}
                                                                    </p>
                                                                </div>
                                                                <div style={{ width: "25%" }}>
                                                                    <p className="text-end">{transaction.method === "TOKEN" ? `-${transaction.payment_amount} ${t("Credits")}` : `${currency(user.country)}${transaction.payment_amount}`}</p>
                                                                    <p className="small-t text-end" style={{ color: transaction.status === "Success" ? "#00C013" : "#F00" }}>{t(transaction.status)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else if (transaction.transactionType === "Reload") {
                                                    return (
                                                        <div
                                                            onClick={() => history.push("user/trans/details", { reload: transaction })}
                                                            className="px-3"
                                                            key={transaction.id}
                                                        >
                                                            <div
                                                                style={{ padding: "20px 10px" }}
                                                                className="info-container mb-3 d-flex box-shadow-card"
                                                            >
                                                                <div style={{ width: "75%" }} className="text-right">
                                                                    <h5 className="font-weight-bold">{t("Credit topup")}</h5>
                                                                    <p className="small-t">
                                                                        {renderDate(transaction.createdAt, true)}, {new Date(transaction.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}
                                                                    </p>
                                                                </div>
                                                                <div style={{ width: "25%" }}>
                                                                    <p className="text-end">+{currency(user.country)}{transaction.amount}</p>
                                                                    <p className="small-t text-end" style={{ color: transaction.status === "Success" ? "#00C013" : "#F00" }}>{t(transaction.status)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else if (transaction.transactionType === "Point") {
                                                    return (
                                                        <div
                                                            onClick={() => history.push("user/trans/details", { point: transaction })}
                                                            className="px-3"
                                                            key={transaction.id}
                                                        >
                                                            <div
                                                                style={{ padding: "20px 10px" }}
                                                                className="info-container mb-3 d-flex box-shadow-card"
                                                            >
                                                                <div style={{ width: "75%" }} className="text-right">
                                                                    <h5 className="font-weight-bold"><Trans i18nKey="{{ point }} History">{{ point: user.loyalty_point_name }} History</Trans></h5>
                                                                    <p className="small-t">
                                                                        {renderDate(transaction.createdAt, true)}, {new Date(transaction.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}
                                                                    </p>
                                                                </div>
                                                                <div style={{ width: "25%" }}>
                                                                    <p className="text-end">{transaction.amount} {user.loyalty_point_name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else if (transaction.transactionType === "Subscription") {
                                                    return (
                                                        <div
                                                            onClick={() => history.push("user/trans/details", { subscription: transaction })}
                                                            className="px-3"
                                                            key={transaction.id}
                                                        >
                                                            <div
                                                                style={{ padding: "20px 10px" }}
                                                                className="info-container mb-3 d-flex box-shadow-card"
                                                            >
                                                                <div style={{ width: "75%" }} className="text-right">
                                                                    <h5 className="font-weight-bold">{t("Laundry Pass")}</h5>
                                                                    <p className="small-t">
                                                                        {renderDate(transaction.createdAt, true)}, {new Date(transaction.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}
                                                                    </p>
                                                                </div>
                                                                <div style={{ width: "25%" }}>
                                                                    <p className="text-end">{currency(user.country)}{transaction.price}</p>
                                                                    <p className="small-t text-end" style={{ color: transaction.status === "Success" ? "#00C013" : "#F00" }}>{t(transaction.status)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return (<></>)
                                                }
                                            })
                                    ) : null
                                }

                                {
                                    selected === "Transaction" ? (
                                        transactions.map(transaction => {
                                            return (
                                                <div
                                                    onClick={() => history.push("user/trans/details", { transaction })}
                                                    className="px-3"
                                                    key={transaction.id}
                                                >
                                                    <div
                                                        style={{ padding: "20px 10px" }}
                                                        className="info-container mb-3 d-flex box-shadow-card"
                                                    >
                                                        <div style={{ width: "75%" }} className="text-right">
                                                            {
                                                                transaction.transaction_type === "Package"
                                                                    ? <h5 className="font-weight-bold">{t("Drop n Go Service")}</h5>
                                                                    : <h5 className="font-weight-bold">{t(transaction.machine_type)} {transaction.machine_name}</h5>
                                                            }
                                                            <p className="small-t">
                                                                {renderDate(transaction.createdAt, true)}, {new Date(transaction.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}
                                                            </p>
                                                        </div>
                                                        <div style={{ width: "25%" }}>
                                                            <p className="text-end">{transaction.method === "TOKEN" ? `-${transaction.payment_amount} ${t("Credits")}` : `${currency(user.country)}${transaction.payment_amount}`}</p>
                                                            <p className="small-t text-end" style={{ color: transaction.status === "Success" ? "#00C013" : "#F00" }}>{t(transaction.status)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : null
                                }

                                {
                                    selected === "Reload" ? (
                                        reloads.map(reload => {
                                            return (
                                                <div
                                                    onClick={() => history.push("user/trans/details", { reload })}
                                                    className="px-3"
                                                    key={reload.id}
                                                >
                                                    <div
                                                        style={{ padding: "20px 10px" }}
                                                        className="info-container mb-3 d-flex box-shadow-card"
                                                    >
                                                        <div style={{ width: "75%" }} className="text-right">
                                                            <h5 className="font-weight-bold">{t("Credit topup")}</h5>
                                                            <p className="small-t">
                                                                {renderDate(reload.createdAt, true)}, {new Date(reload.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}
                                                            </p>
                                                        </div>
                                                        <div style={{ width: "25%" }}>
                                                            <p className="text-end">+{currency(user.country)}{reload.amount}</p>
                                                            <p className="small-t text-end" style={{ color: reload.status === "Success" ? "#00C013" : "#F00" }}>{t(reload.status)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : null
                                }

                                {
                                    selected === "Transfer" ? (
                                        transfers.map(transfer => {
                                            return (
                                                <div
                                                    onClick={() => history.push("user/trans/details", { transfer })}
                                                    className="px-3"
                                                    key={transfer.id}
                                                >
                                                    <div
                                                        style={{ padding: "20px 10px" }}
                                                        className="info-container mb-3 d-flex box-shadow-card"
                                                    >
                                                        <div style={{ width: "75%" }} className="text-right">
                                                            <h5 className="font-weight-bold">{t("Transfer")}: {transfer.amount > 0 ? "+" : null}{transfer.amount} {t("Credits")}</h5>
                                                        </div>
                                                        <div style={{ width: "25%" }}>
                                                            <p className="small-t text-end">{new Date(transfer.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</p>
                                                            <p className="small-t text-end">{renderDate(transfer.createdAt, true)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : null
                                }

                                {
                                    selected === "Point" ? (
                                        points.map(point => {
                                            return (
                                                <div
                                                    onClick={() => history.push("user/trans/details", { point })}
                                                    className="px-3"
                                                    key={point.id}
                                                >
                                                    <div
                                                        style={{ padding: "20px 10px" }}
                                                        className="info-container mb-3 d-flex box-shadow-card"
                                                    >
                                                        <div style={{ width: "75%" }} className="text-right">
                                                            <h5 className="font-weight-bold"><Trans i18nKey="{{ point }} History">{{ point: user.loyalty_point_name }} History</Trans></h5>
                                                            <p className="small-t">
                                                                {renderDate(point.createdAt, true)}, {new Date(point.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}
                                                            </p>
                                                        </div>
                                                        <div style={{ width: "25%" }}>
                                                            <p className="text-end">{point.amount} {user.loyalty_point_name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : null
                                }

                                {
                                    selected === "Subscription" ? (
                                        subscriptionHistory.map(subscription => {
                                            return (
                                                <div
                                                    onClick={() => history.push("user/trans/details", { subscription })}
                                                    className="px-3"
                                                    key={subscription.id}
                                                >
                                                    <div
                                                        style={{ padding: "20px 10px" }}
                                                        className="info-container mb-3 d-flex box-shadow-card"
                                                    >
                                                        <div style={{ width: "75%" }} className="text-right">
                                                            <h5 className="font-weight-bold">{t("Laundry Pass")}</h5>
                                                            <p className="small-t">
                                                                {renderDate(subscription.createdAt, true)}, {new Date(subscription.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}
                                                            </p>
                                                        </div>
                                                        <div style={{ width: "25%" }}>
                                                            <p className="text-end">{currency(user.country)}{subscription.price}</p>
                                                            <p className="small-t text-end" style={{ color: subscription.status === "Success" ? "#00C013" : "#F00" }}>{t(subscription.status)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : null
                                }

                            </div>
                        </div>
                    ) : (
                        <div style={{ padding: "10px 4vw 0", minHeight: "100vh" }}>
                            <NewsSlider inboxNews={true} history={history} />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default CleanproTransaction;