import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import YesButton from '../../../../assets/images/logo/YesButton.png';
import NoButton from '../../../../assets/images/logo/NoButton.png';

import TropicalDreamFabricFreshnerSpray from '../../../../assets/images/logo/Vending1.png';
import ActivFreshFabricFreshnerSpray from '../../../../assets/images/logo/Vending2.png';
import LaundryBag from '../../../../assets/images/logo/Vending3.png';
import DetergentPowder from '../../../../assets/images/logo/Vending4.png';
import FabricSoftener from '../../../../assets/images/logo/Vending5.png';
import OxygenBleach from '../../../../assets/images/logo/Vending6.png';
import { currency } from '../../../../dynamicController';

const VendingPayment = ({ machine, setPage, outlet, user, setSendThis, setShowOverlay, setEndpoint }) => {
    const { t } = useTranslation();
    const [products, setProducts] = useState([
        { price: 0, basePrice: 1.50, quantity: 0 },
        { price: 0, basePrice: 5.00, quantity: 0 }
    ]);

    const [showConfirmPayment, setShowConfirmPayment] = useState(false);

    const addAmount = (index) => {
        const updatedProducts = [...products];
        updatedProducts[index].quantity += 1;
        updatedProducts[index].price = updatedProducts[index].basePrice * updatedProducts[index].quantity;
        setProducts(updatedProducts);
    };

    const minusAmount = (index) => {
        const updatedProducts = [...products];
        if (updatedProducts[index].quantity > 0) {
            updatedProducts[index].quantity -= 1;
        }
        updatedProducts[index].price = updatedProducts[index].basePrice * updatedProducts[index].quantity;
        setProducts(updatedProducts);
    };

    const confirmPayment = () => {
        const totalQuantity = products.reduce((acc, product) => acc + product.quantity, 0);
        if (totalQuantity === 0) {
            return;
        }

        const selectedProducts = products.map(product => ({
            price: product.basePrice,
            quantity: product.quantity,
            total: product.basePrice * product.quantity
        }));

        const totalAmount = selectedProducts.reduce((acc, product) => acc + product.total, 0);

        let sendThis = {
            amount: totalAmount,
            machine,
            paymentAmount: totalAmount,
            outlet,
            kiosk: true,
        };
        let endPoint = "/api/nonuser/payment";

        if (user) {
            sendThis.memberId = user.memberId;
            endPoint = "/api/user/payment";
        }
        setSendThis(sendThis);
        setEndpoint(endPoint);
        setPage('Payment');
    };

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
        }}>
            {!showConfirmPayment ? (
                <div style={{
                    width: '1080px',
                    height: '1920px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '40px'
                }}>
                    <div className="content" style={{ marginBottom: '60px' }}>
                        {products.map((product, index) => {
                            if (
                                (index === 0 && (machine?.vendingItem.includes('softener') || machine?.vendingItem.includes('bleach') || machine?.vendingItem.includes('laundryBeg') || machine?.vendingItem.includes('detergent')))
                                || (index === 1 && (machine?.vendingItem.includes('romantic') || machine?.vendingItem.includes('elegance')))
                            ) {
                                return (
                                    <div key={index} style={{
                                        backgroundColor: '#004FC1',
                                        borderRadius: '20px',
                                        padding: '20px',
                                        marginBottom: '30px',
                                        color: '#fff',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '700px',
                                    }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '20px', gap: '10px' }}>
                                            {index === 0 ? (
                                                <>
                                                    {machine?.vendingItem.includes('softener') && (
                                                        <img src={FabricSoftener} alt="Vending 1" style={{ width: '150px', height: 'auto' }} />
                                                    )}
                                                    {machine?.vendingItem.includes('bleach') && (
                                                        <img src={OxygenBleach} alt="Vending 2" style={{ width: '150px', height: 'auto' }} />
                                                    )}
                                                    {machine?.vendingItem.includes('laundryBeg') && (
                                                        <img src={LaundryBag} alt="Vending 3" style={{ width: '150px', height: 'auto' }} />
                                                    )}
                                                    {machine?.vendingItem.includes('detergent') && (
                                                        <img src={DetergentPowder} alt="Vending 4" style={{ width: '150px', height: 'auto' }} />
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {machine?.vendingItem.includes('romantic') && (
                                                        <img src={TropicalDreamFabricFreshnerSpray} alt="Vending 5" style={{ width: '150px', height: 'auto' }} />
                                                    )}
                                                    {machine?.vendingItem.includes('elegance') && (
                                                        <img src={ActivFreshFabricFreshnerSpray} alt="Vending 6" style={{ width: '150px', height: 'auto' }} />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <p style={{ fontSize: '40px', marginBottom: '20px' }}>{currency(machine.country)}{product.price.toFixed(2)}</p>

                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#fff',
                                            borderRadius: '50px',
                                            padding: '10px 20px',
                                            color: '#000',
                                            fontSize: '36px',
                                            width: '300px',
                                            justifyContent: 'space-between',
                                            marginTop: '20px'
                                        }}>
                                            <button
                                                onClick={() => minusAmount(index)}
                                                style={{
                                                    background: 'transparent',
                                                    border: 'none',
                                                    fontSize: '48px',
                                                    fontWeight: 'bold',
                                                    color: '#004FC1'
                                                }}
                                            >
                                                -
                                            </button>
                                            <span>{product.quantity}</span>
                                            <button
                                                onClick={() => addAmount(index)}
                                                style={{
                                                    background: 'transparent',
                                                    border: 'none',
                                                    fontSize: '48px',
                                                    fontWeight: 'bold',
                                                    color: '#004FC1'
                                                }}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                )
                            } else {
                                return(<div key={index}></div>)
                            }
                        })}
                    </div>

                    <div className="d-flex justify-content-center position-relative" style={{ gap: '60px', marginTop: '80px' }}>
                        <div className="position-relative" style={{ width: '300px', height: '100px' }}>
                            <img
                                src={YesButton}
                                alt="Proceed Button"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    opacity: products.every(product => product.quantity === 0) ? 0.5 : 1,
                                    pointerEvents: products.every(product => product.quantity === 0) ? 'none' : 'auto'
                                }}
                                onClick={() => setShowConfirmPayment(true)}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '48px',
                                    fontWeight: '600',
                                    color: '#fff',
                                    pointerEvents: 'none'
                                }}
                            >
                                {t("Proceed")}
                            </span>
                        </div>

                        <div className="position-relative" style={{ width: '300px', height: '100px' }}>
                            <img
                                src={NoButton}
                                alt="Cancel Button"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={() => setShowOverlay(false)}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '48px',
                                    fontWeight: '600',
                                    color: '#fff',
                                    pointerEvents: 'none'
                                }}
                            >
                                {t("Cancel")}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{
                    width: '1080px',
                    height: '1920px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '40px'
                }}>
                    <h2 style={{ color: '#000', fontSize: '64px', fontWeight: '800', marginBottom: '60px' }}>
                        {t("Confirm Payment")}
                    </h2>

                    <div className="position-relative" style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '60px' }}>
                        <div style={{ textAlign: 'center', maxWidth: '800px', margin: '0 auto' }}>
                            {products[0].quantity > 0 && (
                                <div style={{
                                    backgroundColor: '#004FC1',
                                    borderRadius: '20px',
                                    padding: '20px',
                                    marginBottom: '30px',
                                    color: '#fff',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '20px', gap: '10px' }}>
                                        {machine?.vendingItem.includes('softener') && (
                                            <img src={FabricSoftener} alt="Vending 1" style={{ width: '150px', height: 'auto' }} />
                                        )}
                                        {machine?.vendingItem.includes('bleach') && (
                                            <img src={OxygenBleach} alt="Vending 2" style={{ width: '150px', height: 'auto' }} />
                                        )}
                                        {machine?.vendingItem.includes('laundryBeg') && (
                                            <img src={LaundryBag} alt="Vending 3" style={{ width: '150px', height: 'auto' }} />
                                        )}
                                        {machine?.vendingItem.includes('detergent') && (
                                            <img src={DetergentPowder} alt="Vending 4" style={{ width: '150px', height: 'auto' }} />
                                        )}
                                    </div>
                                    <div style={{
                                        color: '#fff',
                                        fontSize: '36px',
                                        fontWeight: '600',
                                        marginTop: '20px'
                                    }}>
                                        {currency(machine.country)}{products[0].price.toFixed(2)}
                                    </div>
                                    <div style={{
                                        color: '#fff',
                                        fontSize: '24px',
                                        fontWeight: '600',
                                        marginTop: '10px'
                                    }}>
                                        {products[0].quantity}
                                    </div>
                                </div>
                            )}

                            {products[1].quantity > 0 && (
                                <div style={{
                                    backgroundColor: '#004FC1',
                                    borderRadius: '20px',
                                    padding: '20px',
                                    marginBottom: '30px',
                                    color: '#fff',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '20px', gap: '10px' }}>
                                        {machine?.vendingItem.includes('romantic') && (
                                            <img src={TropicalDreamFabricFreshnerSpray} alt="Vending 5" style={{ width: '150px', height: 'auto' }} />
                                        )}
                                        {machine?.vendingItem.includes('elegance') && (
                                            <img src={ActivFreshFabricFreshnerSpray} alt="Vending 6" style={{ width: '150px', height: 'auto' }} />
                                        )}
                                    </div>
                                    <div style={{
                                        color: '#fff',
                                        fontSize: '36px',
                                        fontWeight: '600',
                                        marginTop: '20px'
                                    }}>
                                        {currency(machine.country)}{products[1].price.toFixed(2)}
                                    </div>
                                    <div style={{
                                        color: '#fff',
                                        fontSize: '24px',
                                        fontWeight: '600',
                                        marginTop: '10px'
                                    }}>
                                        {products[1].quantity}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="d-flex justify-content-center position-relative" style={{ gap: '60px', marginTop: '80px' }}>
                        <div className="position-relative" style={{ width: '300px', height: '100px' }}>
                            <img
                                src={YesButton}
                                alt="Pay Button"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={confirmPayment}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '48px',
                                    fontWeight: '600',
                                    color: '#fff',
                                    pointerEvents: 'none'
                                }}
                            >
                                {t("Pay")}
                            </span>
                        </div>

                        <div className="position-relative" style={{ width: '300px', height: '100px' }}>
                            <img
                                src={NoButton}
                                alt="Cancel Button"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={() => setShowConfirmPayment(false)}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '48px',
                                    fontWeight: '600',
                                    color: '#fff',
                                    pointerEvents: 'none'
                                }}
                            >
                                {t("Cancel")}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VendingPayment;
