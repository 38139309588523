/* eslint-disable no-useless-escape */
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";
import { useTranslation, Trans } from "react-i18next";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Modal } from "react-bootstrap";

import { currency } from "../../../../dynamicController";

import dryer1 from "../../../../assets/images/machines/dryer1.png";
import dryer2 from "../../../../assets/images/machines/dryer2.png";
import dryer3 from "../../../../assets/images/machines/dryer3.png";
import dryer4 from "../../../../assets/images/machines/dryer4.png";
import promotionBanner from "../../../../assets/images/logo/promotion.png";

// cleanpro icon
import clock from "../../../../assets/images/icons/icn-time.png";
import ticket from "../../../../assets/images/icons/icn-voucher.png";
import creditBear from "../../../../assets/images/icons/icn-creditGrey.png";
import insufficientToken from "../../../../assets/images/icons/icn-creditInsufficient.png";
import cleanproCreditIcon from "../../../../assets/images/icons/icn-credit.png";
import voucherIcon from "../../../../assets/images/navIcons/icn-voucher.png";

// washup icon
import washupTemperature from "../../../../assets/images/icons/washup-temperature.png";
import washupDuration from "../../../../assets/images/icons/washup-duration.png";

import CleanproDryerPayment from "../cleanpro/DryerPayment";

const mySwal = withReactContent(Swal);
const STORETYPE = process.env.REACT_APP_STORETYPE;
const WITHOUT_MINPRICE_LIMIT = process.env.REACT_APP_DRYER_WITHOUT_MINPRICE_LIMIT

const DryerPayment = ({ machine, outlet, user, theme, setDuration, userLogout, setPage, setSendThis, setShowOverlay, setEndpoint }) => {

    // STATE
    const [amount, setAmount] = useState(machine.running ? parseFloat(machine.step) : parseFloat(machine.priceData.minPrice));
    const [isOpen, setOpen] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(null);
    const [voucherId, setVoucherId] = useState(null);
    const [promotion, setPromotion] = useState(false);
    const [discountCode, setDiscountCode] = useState("");
    const [discountCodeInput, setDiscountCodeInput] = useState("");
    const [discountCodeModal, setDiscountCodeModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [insufficient, setInsufficient] = useState(false);
    const [id, setId] = useState(null);
    const [notificationType, setNotificationType] = useState("");
    const [email, setEmail] = useState(user && user.email ? user.email : "");
    const [number, setNumber] = useState(user && user.number ? user.number : "")
    const [razerBody, setRazerBody] = useState(null);
    const [ghlBody, setGhlBody] = useState(null);
    const [point, setPoint] = useState(false);
    const [temperature, setTemperature] = useState("");
    const [laundryPassOption, setLaundryPassOption] = useState(false);
    const [laundryPass, setLaundryPass] = useState(false);
    const [laundryPassRule, setLaundryPassRule] = useState([]);

    const razerButtonClick = useRef();
    const ghlButtonClick = useRef();

    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        if (machine.isWashup && STORETYPE === "washup") {
            if (machine.priceData.temperature && machine.priceData.default && machine.priceData.temperature.includes(machine.priceData.default)) {
                setTemperature(machine.priceData.default)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (razerBody) {
            razerButtonClick.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [razerBody]);

    useEffect(() => {
        if (ghlBody) {
            ghlButtonClick.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ghlBody]);

    useEffect(() => {
        if (machine.promo.status) {
            if (user) {
                setPromotion(true)
                setDiscountAmount({ type: "Rate", amount: machine.promo.priceData.dryerRate })
            } else {
                if (machine.non_member_promotion) {
                    setPromotion(true)
                    setDiscountAmount({ type: "Rate", amount: machine.promo.priceData.dryerRate })
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user) {
            if (user.subscriptions.length > 0) {
                let dryerSubscription = user.subscriptions.filter(subscription => subscription.dryer_use && !subscription.dryer_used_date)
                if (dryerSubscription.length > 0) {
                    if (machine) {
                        if (!machine.running) {
                            setLaundryPassOption(true)
                            setLaundryPassRule(dryerSubscription[0].rules)
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, machine]);

    // FUNCTIONS
    const buttonStyle = (val) => {
        if (parseFloat(amount) === parseFloat(val)) {
            if (STORETYPE === "washup") {
                return "selected"
            } else if (STORETYPE === "cleanpro") {
                return "selected-dur";
            } else {
                return "selected-temp";
            }
        } else {
            return null;
        }
    };

    const discountText = () => {
        if (discountAmount) {
            if (discountAmount.type === "Flat") {
                if (STORETYPE === "cleanpro" && user && user.tokenSetting) {
                    return `${discountAmount.amount} ${t("credit")}`
                } else {
                    return `${currency(machine.country)} ${discountAmount.amount}`
                }
            } else {
                return `${discountAmount.amount} %`
            }
        }
    };

    const getPaymentAmount = (type) => {
        let payAmount = parseFloat(amount)
        let pointAmount = 0

        if (discountAmount) {
            // promotion / voucher / discount code
            if (discountAmount.type === "Flat") {
                payAmount = parseFloat(amount) - parseFloat(discountAmount.amount)
            } else {
                const rate = (parseFloat(amount) * parseFloat(discountAmount.amount)) / 100
                payAmount = (parseFloat(amount) - rate).toFixed(2)
            }
        }

        if (laundryPass) {
            payAmount = parseFloat(payAmount) - parseFloat(machine.priceData.minPrice)
        }

        if (payAmount <= 0) {
            payAmount = 0
        }

        // point
        if (user && point) {
            if (parseFloat(user.point) >= (parseFloat(payAmount) * parseFloat(user.point_currency))) {
                // if user have sufficient point to pay (all amount pay using point)
                pointAmount = parseFloat(payAmount) * parseFloat(user.point_currency)
                payAmount = 0
            } else {
                // use up all the current point and user still need to pay for the remaining amount
                pointAmount = parseFloat(user.point)
                payAmount = parseFloat(payAmount) - (parseFloat(user.point) / parseFloat(user.point_currency))
            }
        }

        if (type === "paymentAmount") {
            // machine payment amount
            return /\./g.test(payAmount.toString()) ? parseFloat(payAmount).toFixed(2) : payAmount;
        } else if (type === "totalPaymentAmount") {
            // payment amount + sms amount
            if (notificationType === "SMS" && !(user && machine.member_free_sms)) {
                payAmount = parseFloat(payAmount) + parseFloat(machine.sms_notification_amount);
            }
            return /\./g.test(payAmount.toString()) ? parseFloat(payAmount).toFixed(2) : payAmount;
        } else if (type === "pointAmount") {
            // point amount used for discount
            return pointAmount;
        } else if (type === "pointValue") {
            // point amount used for discount in RM
            return /\./g.test((parseFloat(pointAmount) / parseFloat(user.point_currency)).toString()) ?
                (parseFloat(pointAmount) / parseFloat(user.point_currency)).toFixed(2) :
                (parseFloat(pointAmount) / parseFloat(user.point_currency));
        } else if (type === "sms") {
            if (user && machine.member_free_sms) {
                return 0;
            } else {
                return /\./g.test(machine.sms_notification_amount.toString()) ?
                    parseFloat(machine.sms_notification_amount).toFixed(2) :
                    machine.sms_notification_amount;
            }
        }
    }

    const checkDiscountCode = (e) => {
        e.preventDefault();

        if (user && !machine.promo.status) {
            setLoading(true);
            axios
                .post("/api/user/checkDiscountCode", {
                    discountCode: discountCodeInput,
                    outletId: machine.outletId,
                    machineType: machine.type,
                    userId: user.id,
                })
                .then((res) => {
                    let typ = res.data.discountType;
                    let amt = parseFloat(res.data.discountAmount);
                    setDiscountCode(discountCodeInput);
                    setDiscountAmount({ type: typ, amount: amt })
                    setVoucherId(null);
                    setDiscountCodeModal(false);
                    setLoading(false);
                })
                .catch((err) => {
                    setDiscountCodeInput("");
                    setLoading(false);
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                });
        }
    };

    const useVoucher = (e) => {
        if (id) {
            let foundVoucher = user.vouchers.filter((v) => v.id === id)[0];
            if (user && !machine.promo.status) {
                setDiscountAmount({ type: foundVoucher.type, amount: foundVoucher.amount })
                setVoucherId(foundVoucher.id);
                setOpen(false);
            }
        }
    };

    const chooseVoucher = (typ, amt, id) => {
        if (user && !machine.promo.status) {
            setDiscountAmount({ type: typ, amount: amt })
            setVoucherId(id);
            setOpen(false);
        }
    };

    const removeVoucher = () => {
        setDiscountAmount(null);
        setOpen(false);
        setVoucherId(null);
        setDiscountCode("");
        setDiscountCodeInput("");
        setDiscountCodeModal(false);
    };

    const changeDuration = (amt) => {
        setAmount(amt);
    };

    const minusAmount = () => {
        const step = parseFloat(machine.step || 1);
        const newAmount = parseFloat(amount) - step;
        if (newAmount >= parseFloat(machine.priceData.minPrice)) {
            setAmount(newAmount);
        } else if (newAmount >= step && machine.running) {
            // running can less than minPrice
            setAmount(newAmount);
        } else if (newAmount >= step && WITHOUT_MINPRICE_LIMIT) {
            setAmount(newAmount);
        }
    };

    const addAmount = () => {
        const step = parseFloat(machine.step || 1);
        const newAmount = parseFloat(amount) + step;
        const maxPrice = parseFloat(machine.priceData.maxPrice || 20);

        if (newAmount <= maxPrice) {
            setAmount(newAmount);
        }
    };

    const renderVouchers = () => {
        if (user && !machine.promo.status) {
            if (user.vouchers && user.vouchers.length) {
                return user.vouchers
                    .filter((voucher) => voucher.available && voucher.dryer_use)
                    .map((voucher) => {
                        let currentVoucherAmount = voucher.amount
                        return (
                            <div key={voucher.id} className="payment-voucher">
                                {STORETYPE === "cleanpro" ? (
                                    <div
                                        style={{ borderRadius: "0" }}
                                        className="voucher my-3"
                                        key={voucher.id}
                                    >
                                        <div
                                            className="voucher-content center-content"
                                            style={{
                                                color: "black",
                                                position: "relative",
                                                zIndex: "99",
                                                marginTop: "1em",
                                            }}
                                        >
                                            <div
                                                className="d-flex"
                                                style={{
                                                    width: "50%",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    marginLeft: "1em",
                                                    textAlign: "left",
                                                }}
                                            >
                                                <h2 className="font-weight-bold">
                                                    {voucher.type === "Flat" ? <Trans i18nKey="voucherCreditsOff" count={currentVoucherAmount}>{{ currentVoucherAmount }} Credits OFF</Trans> : <Trans i18nKey="voucherPercentageOff" count={currentVoucherAmount}>{{ currentVoucherAmount }}% OFF</Trans>}
                                                </h2>

                                                <small>{voucher.description}</small>

                                                {voucher.expired_date ? (
                                                    <h6 style={{ color: "grey" }} className="m-0 p-0">
                                                        <small>{t("Valid Till")}: {new Date(voucher.expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                                                    </h6>
                                                ) : null}

                                            </div>
                                            <div
                                                style={{ width: "30%" }}
                                                className="center-content"
                                            >
                                                <input
                                                    onChange={() => setId(voucher.id)}
                                                    style={{
                                                        border: "1px solid grey",
                                                        cursor: "pointer",
                                                        opacity: "1",
                                                        pointerEvents: "fill",
                                                        position: "relative",
                                                    }}
                                                    type="radio"
                                                    name="paymentVoucher"
                                                    id={voucher.id}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        key={voucher.id}
                                        onClick={(e) =>
                                            chooseVoucher(voucher.type, voucher.amount, voucher.id)
                                        }
                                        className="list-group-item"
                                    >
                                        <p>{voucher.title}</p>
                                        <h6>
                                            {t("Discount")} :- {voucher.type === "Flat" ? currency(machine.country) : null}
                                            {voucher.amount}
                                            {voucher.type === "Rate" ? "%" : null}
                                        </h6>
                                    </div>
                                )}
                            </div>
                        );
                    });
            } else {
                return (
                    <div className="mt-4">
                        <p style={{ color: "red" }}>{t("No Vouchers Available")}</p>
                    </div>
                );
            }
        } else {
            return null;
        }
    };

    const handleSubmit = (e) => {
        e.target.disabled = true;
        let voucherReminder = false;
        let laundryPassBlocking = false;
        let laundryPassError = ""

        if (user && !machine.promo.status) {
            if (user.vouchers && user.vouchers.length) {
                let userVoucher = user.vouchers.filter((voucher) => voucher.available && voucher.dryer_use);
                if (userVoucher.length !== 0 && !discountAmount && !laundryPass) {
                    voucherReminder = true;
                }
            }
        }

        if (laundryPass) {
            let weightRules = laundryPassRule.filter(rule => rule.type === "Weight")
            let outletRules = laundryPassRule.filter(rule => rule.type === "Outlet")

            if (weightRules.length > 0) {
                let weightValues = weightRules.map(rule => parseInt(rule.value))
                if (weightRules[0].applicable) {
                    // Only can use in these weight
                    if (!weightValues.includes(parseInt(machine.capacity))) {
                        laundryPassBlocking = true
                        laundryPassError = `Laundry Pass only applicable in capacity ${weightValues.join("kg, ")}kg`
                    }
                } else {
                    // cannot use in these weight
                    if (weightValues.includes(parseInt(machine.capacity))) {
                        laundryPassBlocking = true
                        laundryPassError = `Laundry Pass not applicable in capacity ${weightValues.join("kg, ")}kg`
                    }
                }
            }

            if (outletRules.length > 0) {
                let outletValues = outletRules.map(rule => rule.outletId)
                if (outletRules[0].applicable) {
                    // Only can use in these outlet
                    if (!outletValues.includes(machine.outletId)) {
                        laundryPassBlocking = true
                        laundryPassError = "Laundry Pass not applicable in this outlet"
                    }
                } else {
                    // cannot use in these outlet
                    if (outletValues.includes(machine.outletId)) {
                        laundryPassBlocking = true
                        laundryPassError = "Laundry Pass not applicable in this outlet"
                    }
                }
            }
        }

        const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
        const numberRegex = /^\d+$/;

        if (notificationType === "Email" && !email) {
            mySwal.fire("", t("Please fill in your email address"), "info")
            e.target.disabled = false;
        } else if (notificationType === "Email" && !emailRegex.test(email)) {
            mySwal.fire(t("Error"), t("Invalid Email Address"), "error");
            e.target.disabled = false;
        } else if (notificationType === "SMS" && !number) {
            mySwal.fire("", t("Please fill in your phone number"), "info")
            e.target.disabled = false;
        } else if (notificationType === "SMS" && machine.country === "Taiwan" && (!numberRegex.test(number) || number.length !== 10 || number.substring(0, 2) !== "09")) {
            mySwal.fire(t("Error"), t("Invalid Phone Number"), "error");
            e.target.disabled = false;
        } else if (notificationType === "SMS" && machine.country === "Malaysia" && (!numberRegex.test(number) || number.length <= 9 || number.length >= 12 || number.substring(0, 2) !== "01")) {
            mySwal.fire(t("Error"), t("Invalid Phone Number"), "error");
            e.target.disabled = false;
        } else if (laundryPassBlocking) {
            mySwal.fire(t("Error"), t(laundryPassError), "error");
            e.target.disabled = false;
        } else {
            if (voucherReminder) {
                mySwal
                    .fire({
                        title: t("You haven't applied your voucher"),
                        text: t("Would you like to continue your payment without using your voucher?"),
                        showCancelButton: true,
                        confirmButtonText: t("Continue"),
                        cancelButtonText: t("Apply voucher"),
                        confirmButtonColor: STORETYPE === "washup" ? "#004c64" : "#2778c4",
                        customClass: {
                            confirmButton: STORETYPE === "washup" ? "washup-swal-btn pay-without-voucher" : "pay-without-voucher",
                            cancelButton: STORETYPE === "washup" ? "washup-swal-btn apply-voucher" : "apply-voucher",
                        },
                    })
                    .then((click) => {
                        if (click.isConfirmed) {
                            if (machine.online && machine.outletStatus === "online" && outlet) {
                                if (user && user.tokenSetting && user.epayment) {
                                    // E-payment or E-token
                                    mySwal
                                        .fire({
                                            title: STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method"),
                                            text: STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?"),
                                            showDenyButton: true,
                                            showCancelButton: true,
                                            cancelButtonText: t("Cancel"),
                                            denyButtonText: STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet"),
                                            confirmButtonText: STORETYPE === "cleanpro" ? t("Cleanpro Plus credits") : t("e-Token"),
                                            denyButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#67b668",
                                            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#CFA82C" : "#2778c4",
                                            customClass: {
                                                confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-wallet" : STORETYPE === "washup" ? "washup-swal-btn e-wallet" : "e-wallet",
                                                denyButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-token" : STORETYPE === "washup" ? "washup-swal-btn e-token" : "e-token",
                                                cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-secondary-btn cancel-payment" : STORETYPE === "washup" ? "washup-swal-btn cancel-payment" : "cancel-payment"
                                            },
                                        })
                                        .then((click) => {
                                            if (click.isDenied) {
                                                // E WALLET PAYMENT (DEFAULT)
                                                const text = () => {
                                                    if (voucherId && discountAmount && discountAmount.type === "Flat" && (discountAmount.amount > amount)) {
                                                        return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency(machine.country)} ${getPaymentAmount("totalPaymentAmount")} ${t("to")} ${machine.name}?`;
                                                    } else {
                                                        return `${t("Pay")} ${currency(machine.country)} ${getPaymentAmount("totalPaymentAmount")} ${t("to")} ${machine.name}?`;
                                                    }
                                                };

                                                let sendThis = {
                                                    amount,
                                                    machine,
                                                    paymentAmount: getPaymentAmount("paymentAmount"),
                                                    outlet,
                                                    duration: renderDuration(),
                                                    notificationType,
                                                    email,
                                                    sms_amount: getPaymentAmount("sms"),
                                                    number,
                                                    kiosk: true,
                                                    temperature
                                                };
                                                let endPoint = "/api/nonuser/payment";
                                                if (user) {
                                                    sendThis.voucherId = voucherId;
                                                    sendThis.memberId = user.memberId;
                                                    sendThis.discountCode = discountCode;
                                                    sendThis.point_amount = getPaymentAmount("pointAmount");
                                                    sendThis.point_currency = user.point_currency;
                                                    sendThis.laundryPass = laundryPass;
                                                    endPoint = "/api/user/payment";
                                                }

                                                mySwal
                                                    .fire({
                                                        title: t("Confirmation"),
                                                        text: text(),
                                                        icon: "question",
                                                        showCancelButton: true,
                                                        cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                        confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                        reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                        confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                                        customClass: {
                                                            confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                                            cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : ""
                                                        }
                                                    })
                                                    .then((result) => {
                                                        if (result.isConfirmed) {
                                                            if (machine.outletType === "pagbank" && parseFloat(getPaymentAmount("totalPaymentAmount")) > 0) {
                                                                let data = {
                                                                    country: machine.country,
                                                                    description: machine.name,
                                                                    paymentAmount: getPaymentAmount("totalPaymentAmount"),
                                                                    user,
                                                                    endPoint,
                                                                    sendThis,
                                                                }
                                                                history.push("/payment/pagbank", data)
                                                            } else {
                                                                mySwal
                                                                    .fire({
                                                                        text: t("Processing ..."),
                                                                        allowOutsideClick: false,
                                                                        didOpen: () => {
                                                                            mySwal.showLoading();
                                                                            axios
                                                                                .post(endPoint, sendThis)
                                                                                .then((res) => {
                                                                                    if (res.status === 200) {
                                                                                        if (res.data.status === "rm") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            window.location.href = res.data.data;
                                                                                        } else if (res.data.status === "laundro") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            userLogout()
                                                                                        } else if (res.data.status === "razer") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setRazerBody(res.data.data)
                                                                                        } else if (res.data.status === "ghl") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setGhlBody(res.data.data)
                                                                                        }
                                                                                    } else {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                                    }
                                                                                })
                                                                                .catch((err) => {
                                                                                    mySwal
                                                                                        .fire(t("Error"), t(err.response.data.error), "error")
                                                                                        .then(() => (e.target.disabled = false));
                                                                                });
                                                                        },
                                                                    })
                                                                    .then(() => {
                                                                        e.target.disabled = false;
                                                                    });
                                                            }
                                                        } else {
                                                            e.target.disabled = false;
                                                        }
                                                    });
                                            } else if (click.isConfirmed) {
                                                // TOKEN PAYMENT
                                                // if (voucherId) {
                                                // 	// if got voucher, prompt message
                                                // 	mySwal
                                                // 		.fire(
                                                // 			"",
                                                // 			t("You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token."),
                                                // 			"info"
                                                // 		)
                                                // 		.then(() => (e.target.disabled = false));
                                                // } else {
                                                // no voucher, pass
                                                if (parseFloat(user.token) < parseFloat(getPaymentAmount("totalPaymentAmount"))) {
                                                    if (STORETYPE === "cleanpro") {
                                                        setInsufficient(true);
                                                        e.target.disabled = false;
                                                    }
                                                    // no token or not enough token
                                                    else
                                                        mySwal
                                                            .fire({
                                                                title: t("Insufficient e-Tokens"),
                                                                text: t("Reload your e-token now?"),
                                                                showCancelButton: true,
                                                                cancelButtonText: t("Cancel"),
                                                                confirmButtonText: t("Reload now!"),
                                                                confirmButtonColor: STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                                                customClass: {
                                                                    confirmButton: STORETYPE === "washup" ? "washup-swal-btn reload-token" : "reload-token",
                                                                    cancelButton: STORETYPE === "washup" ? "washup-swal-btn cancel-reload-token" : "cancel-reload-token"
                                                                },
                                                            })
                                                            .then((result) => {
                                                                if (result.isConfirmed && result.value)
                                                                    userLogout()
                                                                e.target.disabled = false;
                                                            });
                                                } else {
                                                    mySwal
                                                        .fire({
                                                            title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                                                            html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${getPaymentAmount("totalPaymentAmount")}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
                                                            icon: "question",
                                                            showCancelButton: true,
                                                            cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                            confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                            reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                                            customClass: {
                                                                confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                                                cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                                            }
                                                        })
                                                        .then((result) => {
                                                            if (result.isConfirmed && result.value) {
                                                                mySwal
                                                                    .fire({
                                                                        text: t("Processing ..."),
                                                                        allowOutsideClick: false,
                                                                        didOpen: () => {
                                                                            mySwal.showLoading();
                                                                            const sendThis = {
                                                                                amount,
                                                                                paymentAmount: getPaymentAmount("paymentAmount"),
                                                                                memberId: user.memberId,
                                                                                machine,
                                                                                token: true,
                                                                                outlet,
                                                                                voucherId,
                                                                                discountCode,
                                                                                duration: renderDuration(),
                                                                                notificationType,
                                                                                email,
                                                                                sms_amount: getPaymentAmount("sms"),
                                                                                number,
                                                                                kiosk: true,
                                                                                point_amount: getPaymentAmount("pointAmount"),
                                                                                point_currency: user.point_currency,
                                                                                temperature,
                                                                                laundryPass,
                                                                            };
                                                                            axios
                                                                                .post("/api/user/payment", sendThis)
                                                                                .then((res) => {
                                                                                    if (res.status === 200) {
                                                                                        if (res.data.status === "rm") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            window.location.href = res.data.data;
                                                                                        } else if (res.data.status === "laundro") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            userLogout()
                                                                                        } else if (res.data.status === "razer") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setRazerBody(res.data.data)
                                                                                        } else if (res.data.status === "ghl") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setGhlBody(res.data.data)
                                                                                        }
                                                                                    } else {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                                    }
                                                                                })
                                                                                .catch((err) => {
                                                                                    mySwal
                                                                                        .fire(t("Error"), t(err.response.data.error), "error")
                                                                                        .then(() => (e.target.disabled = false));
                                                                                });

                                                                            e.target.disabled = false;
                                                                        },
                                                                    })
                                                                    .then(() => {
                                                                        e.target.disabled = false;
                                                                    });
                                                            } else {
                                                                e.target.disabled = false;
                                                            }
                                                        });
                                                }
                                                //}
                                            } else {
                                                e.target.disabled = false;
                                            }
                                        });
                                } else if (user && user.tokenSetting && !user.epayment) {
                                    // Only E-Token
                                    if (parseFloat(user.token) < parseFloat(getPaymentAmount("totalPaymentAmount"))) {
                                        if (STORETYPE === "cleanpro") {
                                            setInsufficient(true);
                                            e.target.disabled = false;
                                        }
                                        // no token or not enough token
                                        else
                                            mySwal
                                                .fire({
                                                    title: t("Insufficient e-Tokens"),
                                                    text: t("Reload your e-token now?"),
                                                    showCancelButton: true,
                                                    cancelButtonText: t("Cancel"),
                                                    confirmButtonText: t("Reload now!"),
                                                    confirmButtonColor: STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                                    customClass: {
                                                        confirmButton: STORETYPE === "washup" ? "washup-swal-btn reload-token" : "reload-token",
                                                        cancelButton: STORETYPE === "washup" ? "washup-swal-btn cancel-reload-token" : "cancel-reload-token"
                                                    },
                                                })
                                                .then((result) => {
                                                    if (result.isConfirmed && result.value)
                                                        userLogout()
                                                    e.target.disabled = false;
                                                });
                                    } else {
                                        mySwal
                                            .fire({
                                                title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                                                html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${getPaymentAmount("totalPaymentAmount")}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
                                                icon: "question",
                                                showCancelButton: true,
                                                cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                                customClass: {
                                                    confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                                    cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                                }
                                            })
                                            .then((result) => {
                                                if (result.isConfirmed && result.value) {
                                                    mySwal
                                                        .fire({
                                                            text: t("Processing ..."),
                                                            allowOutsideClick: false,
                                                            didOpen: () => {
                                                                mySwal.showLoading();
                                                                const sendThis = {
                                                                    amount,
                                                                    paymentAmount: getPaymentAmount("paymentAmount"),
                                                                    memberId: user.memberId,
                                                                    machine,
                                                                    token: true,
                                                                    outlet,
                                                                    voucherId,
                                                                    discountCode,
                                                                    duration: renderDuration(),
                                                                    notificationType,
                                                                    email,
                                                                    sms_amount: getPaymentAmount("sms"),
                                                                    number,
                                                                    kiosk: true,
                                                                    point_amount: getPaymentAmount("pointAmount"),
                                                                    point_currency: user.point_currency,
                                                                    temperature,
                                                                    laundryPass,
                                                                };
                                                                axios
                                                                    .post("/api/user/payment", sendThis)
                                                                    .then((res) => {
                                                                        if (res.status === 200) {
                                                                            if (res.data.status === "rm") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                window.location.href = res.data.data;
                                                                            } else if (res.data.status === "laundro") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                userLogout()
                                                                            } else if (res.data.status === "razer") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setRazerBody(res.data.data)
                                                                            } else if (res.data.status === "ghl") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setGhlBody(res.data.data)
                                                                            }
                                                                        } else {
                                                                            e.target.disabled = false;
                                                                            mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                        }
                                                                    })
                                                                    .catch((err) => {
                                                                        mySwal
                                                                            .fire(t("Error"), t(err.response.data.error), "error")
                                                                            .then(() => (e.target.disabled = false));
                                                                    });

                                                                e.target.disabled = false;
                                                            },
                                                        })
                                                        .then(() => {
                                                            e.target.disabled = false;
                                                        });
                                                } else {
                                                    e.target.disabled = false;
                                                }
                                            });
                                    }
                                } else {
                                    // Only E-payment
                                    const text = () => {
                                        if (voucherId && discountAmount && discountAmount.type === "Flat" && (discountAmount.amount > amount)) {
                                            return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency(machine.country)} ${getPaymentAmount("totalPaymentAmount")} ${t("to")} ${machine.name}?`;
                                        } else {
                                            return `${t("Pay")} ${currency(machine.country)} ${getPaymentAmount("totalPaymentAmount")} ${t("to")} ${machine.name}?`;
                                        }
                                    };

                                    let sendThis = {
                                        amount,
                                        machine,
                                        paymentAmount: getPaymentAmount("paymentAmount"),
                                        outlet,
                                        duration: renderDuration(),
                                        notificationType,
                                        email,
                                        sms_amount: getPaymentAmount("sms"),
                                        number,
                                        kiosk: true,
                                        temperature,
                                    };
                                    let endPoint = "/api/nonuser/payment";
                                    if (user) {
                                        sendThis.voucherId = voucherId;
                                        sendThis.memberId = user.memberId;
                                        sendThis.discountCode = discountCode;
                                        sendThis.point_amount = getPaymentAmount("pointAmount");
                                        sendThis.point_currency = user.point_currency;
                                        sendThis.laundryPass = laundryPass;
                                        endPoint = "/api/user/payment";
                                    }

                                    mySwal
                                        .fire({
                                            title: t("Confirmation"),
                                            text: text(),
                                            icon: "question",
                                            showCancelButton: true,
                                            cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                            confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                            reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                            customClass: {
                                                confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                                cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                            }
                                        })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                if (machine.outletType === "pagbank" && parseFloat(getPaymentAmount("totalPaymentAmount")) > 0) {
                                                    let data = {
                                                        country: machine.country,
                                                        description: machine.name,
                                                        paymentAmount: getPaymentAmount("totalPaymentAmount"),
                                                        user,
                                                        endPoint,
                                                        sendThis,
                                                    }
                                                    history.push("/payment/pagbank", data)
                                                } else {
                                                    mySwal
                                                        .fire({
                                                            text: t("Processing ..."),
                                                            allowOutsideClick: false,
                                                            didOpen: () => {
                                                                mySwal.showLoading();
                                                                axios
                                                                    .post(endPoint, sendThis)
                                                                    .then((res) => {
                                                                        if (res.status === 200) {
                                                                            if (res.data.status === "rm") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                window.location.href = res.data.data;
                                                                            } else if (res.data.status === "laundro") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                userLogout()
                                                                            } else if (res.data.status === "razer") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setRazerBody(res.data.data)
                                                                            } else if (res.data.status === "ghl") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setGhlBody(res.data.data)
                                                                            }
                                                                        } else {
                                                                            e.target.disabled = false;
                                                                            mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                        }
                                                                    })
                                                                    .catch((err) => {
                                                                        mySwal
                                                                            .fire(t("Error"), t(err.response.data.error), "error")
                                                                            .then(() => {
                                                                                e.target.disabled = false;
                                                                                if (err.response.data.error === "Please log in to make the payment") {
                                                                                    userLogout()
                                                                                }
                                                                            });
                                                                    });
                                                            },
                                                        })
                                                        .then(() => {
                                                            e.target.disabled = false;
                                                        });
                                                }
                                            } else {
                                                e.target.disabled = false;
                                            }
                                        });
                                }
                            } else {
                                mySwal
                                    .fire(t("Error"), t("This machine is currently offline, please try again later."), "error")
                                    .then(() => (e.target.disabled = false));
                            }
                        } else {
                            e.target.disabled = false;
                            setOpen(true);
                        }
                    });
            } else {
                if (machine.online && machine.outletStatus === "online" && outlet) {
                    if (user && user.tokenSetting && user.epayment) {
                        // E-payment or E-token
                        mySwal
                            .fire({
                                title: STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method"),
                                text: STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?"),
                                showDenyButton: true,
                                showCancelButton: true,
                                cancelButtonText: t("Cancel"),
                                denyButtonText: STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet"),
                                confirmButtonText: STORETYPE === "cleanpro" ? t("Cleanpro Plus credits") : t("e-Token"),
                                denyButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#67b668",
                                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#CFA82C" : "#2778c4",
                                customClass: {
                                    confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-wallet" : STORETYPE === "washup" ? "washup-swal-btn e-wallet" : "e-wallet",
                                    denyButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-token" : STORETYPE === "washup" ? "washup-swal-btn e-token" : "e-token",
                                    cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-secondary-btn cancel-payment" : STORETYPE === "washup" ? "washup-swal-btn cancel-payment" : "cancel-payment"
                                },
                            })
                            .then((click) => {
                                if (click.isDenied) {
                                    // E WALLET PAYMENT (DEFAULT)
                                    const text = () => {
                                        if (voucherId && discountAmount && discountAmount.type === "Flat" && (discountAmount.amount > amount)) {
                                            return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency(machine.country)} ${getPaymentAmount("totalPaymentAmount")} ${t("to")} ${machine.name}?`;
                                        } else {
                                            return `${t("Pay")} ${currency(machine.country)} ${getPaymentAmount("totalPaymentAmount")} ${t("to")} ${machine.name}?`;
                                        }
                                    };

                                    let sendThis = {
                                        amount,
                                        machine,
                                        paymentAmount: getPaymentAmount("paymentAmount"),
                                        outlet,
                                        duration: renderDuration(),
                                        notificationType,
                                        email,
                                        sms_amount: getPaymentAmount("sms"),
                                        number,
                                        kiosk: true,
                                        temperature,
                                    };
                                    let endPoint = "/api/nonuser/payment";
                                    if (user) {
                                        sendThis.voucherId = voucherId;
                                        sendThis.memberId = user.memberId;
                                        sendThis.discountCode = discountCode;
                                        sendThis.point_amount = getPaymentAmount("pointAmount");
                                        sendThis.point_currency = user.point_currency;
                                        sendThis.laundryPass = laundryPass;
                                        endPoint = "/api/user/payment";
                                    }

                                    mySwal
                                        .fire({
                                            title: t("Confirmation"),
                                            text: text(),
                                            icon: "question",
                                            showCancelButton: true,
                                            cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                            confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                            reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                            customClass: {
                                                confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                                cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : ""
                                            }
                                        })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                if (machine.outletType === "pagbank" && parseFloat(getPaymentAmount("totalPaymentAmount")) > 0) {
                                                    let data = {
                                                        country: machine.country,
                                                        description: machine.name,
                                                        paymentAmount: getPaymentAmount("totalPaymentAmount"),
                                                        user,
                                                        endPoint,
                                                        sendThis,
                                                    }
                                                    history.push("/payment/pagbank", data)
                                                } else {
                                                    mySwal
                                                        .fire({
                                                            text: t("Processing ..."),
                                                            allowOutsideClick: false,
                                                            didOpen: () => {
                                                                mySwal.showLoading();
                                                                axios
                                                                    .post(endPoint, sendThis)
                                                                    .then((res) => {
                                                                        if (res.status === 200) {
                                                                            if (res.data.status === "rm") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                window.location.href = res.data.data;
                                                                            } else if (res.data.status === "laundro") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                userLogout()
                                                                            } else if (res.data.status === "razer") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setRazerBody(res.data.data)
                                                                            } else if (res.data.status === "ghl") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setGhlBody(res.data.data)
                                                                            }
                                                                        } else {
                                                                            e.target.disabled = false;
                                                                            mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                        }
                                                                    })
                                                                    .catch((err) => {
                                                                        mySwal
                                                                            .fire(t("Error"), t(err.response.data.error), "error")
                                                                            .then(() => (e.target.disabled = false));
                                                                    });
                                                            },
                                                        })
                                                        .then(() => {
                                                            e.target.disabled = false;
                                                        });
                                                }
                                            } else {
                                                e.target.disabled = false;
                                            }
                                        });
                                } else if (click.isConfirmed) {
                                    // TOKEN PAYMENT
                                    // if (voucherId) {
                                    // 	// if got voucher, prompt message
                                    // 	mySwal
                                    // 		.fire(
                                    // 			"",
                                    // 			t("You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token."),
                                    // 			"info"
                                    // 		)
                                    // 		.then(() => (e.target.disabled = false));
                                    // } else {
                                    // no voucher, pass
                                    if (parseFloat(user.token) < parseFloat(getPaymentAmount("totalPaymentAmount"))) {
                                        if (STORETYPE === "cleanpro") {
                                            setInsufficient(true);
                                            e.target.disabled = false;
                                        }
                                        // no token or not enough token
                                        else
                                            mySwal
                                                .fire({
                                                    title: t("Insufficient e-Tokens"),
                                                    text: t("Reload your e-token now?"),
                                                    showCancelButton: true,
                                                    cancelButtonText: t("Cancel"),
                                                    confirmButtonText: t("Reload now!"),
                                                    confirmButtonColor: STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                                    customClass: {
                                                        confirmButton: STORETYPE === "washup" ? "washup-swal-btn reload-token" : "reload-token",
                                                        cancelButton: STORETYPE === "washup" ? "washup-swal-btn cancel-reload-token" : "cancel-reload-token"
                                                    },
                                                })
                                                .then((result) => {
                                                    if (result.isConfirmed && result.value)
                                                        userLogout()
                                                    e.target.disabled = false;
                                                });
                                    } else {
                                        mySwal
                                            .fire({
                                                title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                                                html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${getPaymentAmount("totalPaymentAmount")}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
                                                icon: "question",
                                                showCancelButton: true,
                                                cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                                customClass: {
                                                    confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                                    cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                                }
                                            })
                                            .then((result) => {
                                                if (result.isConfirmed && result.value) {
                                                    mySwal
                                                        .fire({
                                                            text: t("Processing ..."),
                                                            allowOutsideClick: false,
                                                            didOpen: () => {
                                                                mySwal.showLoading();
                                                                const sendThis = {
                                                                    amount,
                                                                    paymentAmount: getPaymentAmount("paymentAmount"),
                                                                    memberId: user.memberId,
                                                                    machine,
                                                                    token: true,
                                                                    outlet,
                                                                    voucherId,
                                                                    discountCode,
                                                                    duration: renderDuration(),
                                                                    notificationType,
                                                                    email,
                                                                    sms_amount: getPaymentAmount("sms"),
                                                                    number,
                                                                    kiosk: true,
                                                                    point_amount: getPaymentAmount("pointAmount"),
                                                                    point_currency: user.point_currency,
                                                                    temperature,
                                                                    laundryPass,
                                                                };
                                                                axios
                                                                    .post("/api/user/payment", sendThis)
                                                                    .then((res) => {
                                                                        if (res.status === 200) {
                                                                            if (res.data.status === "rm") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                window.location.href = res.data.data;
                                                                            } else if (res.data.status === "laundro") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                userLogout()
                                                                            } else if (res.data.status === "razer") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setRazerBody(res.data.data)
                                                                            } else if (res.data.status === "ghl") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setGhlBody(res.data.data)
                                                                            }
                                                                        } else {
                                                                            e.target.disabled = false;
                                                                            mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                        }
                                                                    })
                                                                    .catch((err) => {
                                                                        mySwal
                                                                            .fire(t("Error"), t(err.response.data.error), "error")
                                                                            .then(() => (e.target.disabled = false));
                                                                    });

                                                                e.target.disabled = false;
                                                            },
                                                        })
                                                        .then(() => {
                                                            e.target.disabled = false;
                                                        });
                                                } else {
                                                    e.target.disabled = false;
                                                }
                                            });
                                    }
                                    //}
                                } else {
                                    e.target.disabled = false;
                                }
                            });
                    } else if (user && user.tokenSetting && !user.epayment) {
                        // Only E-Token
                        if (parseFloat(user.token) < parseFloat(getPaymentAmount("totalPaymentAmount"))) {
                            if (STORETYPE === "cleanpro") {
                                setInsufficient(true);
                                e.target.disabled = false;
                            }
                            // no token or not enough token
                            else
                                mySwal
                                    .fire({
                                        title: t("Insufficient e-Tokens"),
                                        text: t("Reload your e-token now?"),
                                        showCancelButton: true,
                                        cancelButtonText: t("Cancel"),
                                        confirmButtonText: t("Reload now!"),
                                        confirmButtonColor: STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                        customClass: {
                                            confirmButton: STORETYPE === "washup" ? "washup-swal-btn reload-token" : "reload-token",
                                            cancelButton: STORETYPE === "washup" ? "washup-swal-btn cancel-reload-token" : "cancel-reload-token"
                                        },
                                    })
                                    .then((result) => {
                                        if (result.isConfirmed && result.value)
                                            userLogout()
                                        e.target.disabled = false;
                                    });
                        } else {
                            mySwal
                                .fire({
                                    title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                                    html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${getPaymentAmount("totalPaymentAmount")}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
                                    icon: "question",
                                    showCancelButton: true,
                                    cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                    confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                    reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                    confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                    customClass: {
                                        confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                        cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                    }
                                })
                                .then((result) => {
                                    if (result.isConfirmed && result.value) {
                                        mySwal
                                            .fire({
                                                text: t("Processing ..."),
                                                allowOutsideClick: false,
                                                didOpen: () => {
                                                    mySwal.showLoading();
                                                    const sendThis = {
                                                        amount,
                                                        paymentAmount: getPaymentAmount("paymentAmount"),
                                                        memberId: user.memberId,
                                                        machine,
                                                        token: true,
                                                        outlet,
                                                        voucherId,
                                                        discountCode,
                                                        duration: renderDuration(),
                                                        notificationType,
                                                        email,
                                                        sms_amount: getPaymentAmount("sms"),
                                                        number,
                                                        kiosk: true,
                                                        point_amount: getPaymentAmount("pointAmount"),
                                                        point_currency: user.point_currency,
                                                        temperature,
                                                        laundryPass,
                                                    };
                                                    axios
                                                        .post("/api/user/payment", sendThis)
                                                        .then((res) => {
                                                            if (res.status === 200) {
                                                                if (res.data.status === "rm") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    window.location.href = res.data.data;
                                                                } else if (res.data.status === "laundro") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    userLogout()
                                                                } else if (res.data.status === "razer") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    setRazerBody(res.data.data)
                                                                } else if (res.data.status === "ghl") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    setGhlBody(res.data.data)
                                                                }
                                                            } else {
                                                                e.target.disabled = false;
                                                                mySwal.fire(t("Error"), t("Response Error"), "error");
                                                            }
                                                        })
                                                        .catch((err) => {
                                                            mySwal
                                                                .fire(t("Error"), t(err.response.data.error), "error")
                                                                .then(() => (e.target.disabled = false));
                                                        });

                                                    e.target.disabled = false;
                                                },
                                            })
                                            .then(() => {
                                                e.target.disabled = false;
                                            });
                                    } else {
                                        e.target.disabled = false;
                                    }
                                });
                        }
                    } else {
                        // Only E-payment
                        const text = () => {
                            if (voucherId && discountAmount && discountAmount.type === "Flat" && (discountAmount.amount > amount)) {
                                return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency(machine.country)} ${getPaymentAmount("totalPaymentAmount")} ${t("to")} ${machine.name}?`;
                            } else {
                                return `${t("Pay")} ${currency(machine.country)} ${getPaymentAmount("totalPaymentAmount")} ${t("to")} ${machine.name}?`;
                            }
                        };

                        let sendThis = {
                            amount,
                            machine,
                            paymentAmount: getPaymentAmount("paymentAmount"),
                            outlet,
                            duration: renderDuration(),
                            notificationType,
                            email,
                            sms_amount: getPaymentAmount("sms"),
                            number,
                            kiosk: true,
                            temperature,
                        };
                        let endPoint = "/api/nonuser/payment";
                        if (user) {
                            sendThis.voucherId = voucherId;
                            sendThis.memberId = user.memberId;
                            sendThis.discountCode = discountCode;
                            sendThis.point_amount = getPaymentAmount("pointAmount");
                            sendThis.point_currency = user.point_currency;
                            sendThis.laundryPass = laundryPass;
                            endPoint = "/api/user/payment";
                        }

                        mySwal
                            .fire({
                                title: t("Confirmation"),
                                text: text(),
                                icon: "question",
                                showCancelButton: true,
                                cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
                                customClass: {
                                    confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                    cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                                }
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    if (machine.outletType === "pagbank" && parseFloat(getPaymentAmount("totalPaymentAmount")) > 0) {
                                        let data = {
                                            country: machine.country,
                                            description: machine.name,
                                            paymentAmount: getPaymentAmount("totalPaymentAmount"),
                                            user,
                                            endPoint,
                                            sendThis,
                                        }
                                        history.push("/payment/pagbank", data)
                                    } else {
                                        mySwal
                                            .fire({
                                                text: t("Processing ..."),
                                                allowOutsideClick: false,
                                                didOpen: () => {
                                                    mySwal.showLoading();
                                                    axios
                                                        .post(endPoint, sendThis)
                                                        .then((res) => {
                                                            if (res.status === 200) {
                                                                if (res.data.status === "rm") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    window.location.href = res.data.data;
                                                                } else if (res.data.status === "laundro") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    userLogout()
                                                                } else if (res.data.status === "razer") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    setRazerBody(res.data.data)
                                                                } else if (res.data.status === "ghl") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    setGhlBody(res.data.data)
                                                                }
                                                            } else {
                                                                e.target.disabled = false;
                                                                mySwal.fire(t("Error"), t("Response Error"), "error");
                                                            }
                                                        })
                                                        .catch((err) => {
                                                            mySwal
                                                                .fire(t("Error"), t(err.response.data.error), "error")
                                                                .then(() => {
                                                                    e.target.disabled = false;
                                                                    if (err.response.data.error === "Please log in to make the payment") {
                                                                        userLogout()
                                                                    }
                                                                });
                                                        });
                                                },
                                            })
                                            .then(() => {
                                                e.target.disabled = false;
                                            });
                                    }
                                } else {
                                    e.target.disabled = false;
                                }
                            });
                    }
                } else {
                    mySwal
                        .fire(t("Error"), t("This machine is currently offline, please try again later."), "error")
                        .then(() => (e.target.disabled = false));
                }
            }
        }
    };

    const renderSelection = ind => {
        const minPrice = parseFloat(machine.priceData.minPrice);
        const runTime = parseFloat(machine.priceData.runTime);

        const price = minPrice + ind;

        if (machine.priceData.initialTime && !machine.running) {
            return parseFloat(machine.priceData.initialTime) + ((price - parseFloat(machine.priceData.minPrice)) * runTime);
        } else {
            return price * runTime;
        }
    };

    const renderDuration = () => {

        if (machine.priceData.initialTime && !machine.running) {
            // cannot less than min price
            return parseFloat(machine.priceData.initialTime) + ((parseFloat(amount) - parseFloat(machine.priceData.minPrice)) * parseFloat(machine.priceData.runTime))
        } else {
            // can less than min price
            return parseFloat(amount) * parseFloat(machine.priceData.runTime);
        }
    };

    useEffect(() => {
        setDuration(renderDuration());
    }, [renderDuration]);

    return (
        <div id="dryer-payment" className="mt-4">

            {STORETYPE === "cleanpro" ?
                <CleanproDryerPayment
                    amount={amount}
                    machine={machine}
                    minusAmount={minusAmount}
                    addAmount={addAmount}
                    user={user}
                    setPage={setPage}
                    getPaymentAmount={getPaymentAmount}
                    outlet={outlet}
                    notificationType={notificationType}
                    email={email}
                    number={number}
                    renderDuration={renderDuration}
                    voucherId={voucherId}
                    discountCode={discountCode}
                    temperature={temperature}
                    setSendThis={setSendThis}
                    setShowOverlay={setShowOverlay}
                    setEndpoint={setEndpoint}
                /> : (
                    <>

                        {machine.promo.status && promotion ? (
                            <div className={STORETYPE === "cleanpro" ? "text-center mb-5 pt-2" : "text-center mb-5"}>
                                <img
                                    src={promotionBanner}
                                    alt="promotion banner"
                                    className="img-fluid m-auto"
                                    style={{ height: "90px", width: "auto" }}
                                />
                            </div>
                        ) : null}

                        {STORETYPE !== "cleanpro" && STORETYPE !== "washup" && (
                            <div className="payment-info">
                                <div className="payment-info-box">
                                    <div className="payment-info-image">
                                        <img src={dryer1} alt="dryer1" className="img-fluid" />
                                    </div>
                                    <p className="text-secondary font-weight-bold">{t("MACHINE")}</p>
                                    <p className="font-weight-bold">{machine.name}</p>
                                </div>
                                <div className="payment-info-box">
                                    <div className="payment-info-image">
                                        <img src={dryer2} alt="dryer2" className="img-fluid" />
                                    </div>
                                    <p className="text-secondary font-weight-bold">{t("TYPE")}</p>
                                    <p className="font-weight-bold">{t(machine.type)}</p>
                                </div>
                                <div className="payment-info-box">
                                    <div className="payment-info-image">
                                        <img src={dryer3} alt="dryer3" className="img-fluid" />
                                    </div>
                                    <p className="text-secondary font-weight-bold">{t("CAPACITY")}</p>
                                    <p className="font-weight-bold">{machine.capacity}</p>
                                </div>
                                <div className="payment-info-box">
                                    <div className="payment-info-image">
                                        <img src={dryer4} alt="dryer4" className="img-fluid" />
                                    </div>
                                    <p className="text-secondary font-weight-bold">{t("DURATION")}</p>
                                    <p className="font-weight-bold">
                                        {renderDuration()} {t("MIN")}
                                    </p>
                                </div>
                            </div>
                        )}

                        {
                            machine.isWashup && STORETYPE === "washup" && machine.priceData.temperature ? (
                                <>
                                    <h6 style={{ margin: "20px 0 5px 0" }}>
                                        <img src={washupTemperature} alt="" />
                                        <strong className="ms-2">{t("Select Temperature")}</strong>
                                    </h6>
                                    <div className="dng-selection-buttons">
                                        {
                                            machine.priceData.temperature.map((temp, index) => {
                                                return (<button key={index} onClick={() => setTemperature(temp)} className={temp === temperature ? "selected" : ""}>{t(temp)}</button>)
                                            })
                                        }
                                    </div>
                                </>
                            ) : null
                        }

                        {STORETYPE === "cleanpro" && !machine.running ? (
                            <h6 style={{ margin: "20px 0 10px 0", padding: "0px 5vw" }}>
                                <img src={clock} alt="" style={{ width: "19px" }} />
                                <span
                                    style={{
                                        marginLeft: "10px",
                                        fontWeight: "600",
                                        color: " #333333",
                                    }}
                                >
                                    {machine.running ? t("Add drying time") : t("Choose duration")}
                                </span>
                            </h6>
                        ) : STORETYPE === "washup" ? (
                            <h6 style={{ margin: "20px 0 5px 0" }}>
                                <img src={washupDuration} alt="" />
                                <strong className="ms-2">{t("Select Duration")}</strong>
                            </h6>
                        ) : null}

                        {
                            STORETYPE === "cleanpro" && machine.running ? (
                                <div className="text-center mt-3 mb-4">
                                    <p style={{ color: "#333333", fontSize: "22px", fontWeight: 500 }}>{t("Machine is currently busy")}</p>
                                    <p style={{ color: "#333333", fontSize: "16px", fontWeight: 300 }}>{t("Do you want to extend drying time?")}</p>
                                </div>
                            ) : (
                                <div className={STORETYPE === "washup" ? "dng-selection-buttons" : "selection-buttons"}>
                                    {STORETYPE === "cleanpro" || STORETYPE === "washup" ? (
                                        <>
                                            <button
                                                id="first-duration"
                                                className={buttonStyle(parseFloat(machine.priceData.minPrice))}
                                                onClick={(e) => changeDuration(parseFloat(machine.priceData.minPrice))}
                                            >
                                                {STORETYPE !== "washup" && <i className="bi bi-clock-fill"></i>}{" "}
                                                {renderSelection(0)}{" "}
                                                {t("min")}
                                                {STORETYPE === "washup" ? (
                                                    <>
                                                        <br />
                                                        <span>{currency(machine.country)}{parseFloat(machine.priceData.minPrice) + 0}</span>
                                                    </>
                                                ) : null}
                                            </button>
                                            <button
                                                id="second-duration"
                                                className={buttonStyle(parseFloat(machine.priceData.minPrice) + machine.step)}
                                                onClick={(e) => changeDuration(parseFloat(machine.priceData.minPrice) + machine.step)}
                                            >
                                                {STORETYPE !== "washup" && <i className="bi bi-clock-fill"></i>}{" "}
                                                {renderSelection(machine.step)}{" "}
                                                {t("min")}
                                                {STORETYPE === "washup" ? (
                                                    <>
                                                        <br />
                                                        <span>{currency(machine.country)}{parseFloat(machine.priceData.minPrice) + machine.step}</span>
                                                    </>
                                                ) : null}
                                            </button>
                                            <button
                                                id="third-duration"
                                                className={buttonStyle(parseFloat(machine.priceData.minPrice) + (machine.step * 2))}
                                                onClick={(e) => changeDuration(parseFloat(machine.priceData.minPrice) + (machine.step * 2))}
                                            >
                                                {STORETYPE !== "washup" && <i className="bi bi-clock-fill"></i>}{" "}
                                                {renderSelection(machine.step * 2)}{" "}
                                                {t("min")}
                                                {STORETYPE === "washup" ? (
                                                    <>
                                                        <br />
                                                        <span>{currency(machine.country)}{parseFloat(machine.priceData.minPrice) + (machine.step * 2)}</span>
                                                    </>
                                                ) : null}
                                            </button>
                                        </>
                                    ) : null}
                                </div>
                            )
                        }

                        {STORETYPE !== "cleanpro" ? (
                            <div className="payment-amount">
                                <span id="minus-amount" onClick={(e) => minusAmount()}>
                                    <i className="bi bi-dash-circle-fill"></i>
                                    {
                                        STORETYPE === "washup" && machine.step && machine.priceData.runTime
                                            ? (
                                                <>
                                                    <br />
                                                    <span>{machine.priceData.runTime * machine.step} {t("min")}</span>
                                                </>
                                            ) : null
                                    }
                                </span>

                                <div>
                                    <h6 className="m-0 p-0 font-weight-bold text-secondary">
                                        {t("Duration")}
                                    </h6>
                                    <h3 className="m-0 p-0 font-weight-bold">
                                        {renderDuration()} {t("min")}
                                    </h3>
                                </div>

                                <span id="add-amount" onClick={(e) => addAmount()}>
                                    <i className="bi bi-plus-circle-fill"></i>
                                    {
                                        STORETYPE === "washup" && machine.step && machine.priceData.runTime ? (
                                            <>
                                                <br />
                                                <span>{machine.priceData.runTime * machine.step} {t("min")}</span>
                                            </>
                                        ) : null
                                    }
                                </span>
                            </div>
                        ) : (
                            <div className={machine.running ? "text-center mt-3" : "mt-3"} style={{ padding: "0px 5vw" }}>
                                <span style={{ fontWeight: "600", color: " #333333" }}>
                                    {machine.running ? t("Add drying time") : t("Custom duration")}
                                </span>
                                <div className="custom-duration mt-2">
                                    <p id="cleanpro-minus-amount" onClick={(e) => minusAmount()}>-</p>
                                    <h3 style={{ marginTop: "auto", marginBottom: "auto" }}>
                                        {renderDuration()} {t("min")}
                                    </h3>
                                    <p id="cleanpro-add-amount" onClick={(e) => addAmount()}>+</p>
                                </div>
                                {
                                    !machine.running ? (
                                        <p style={{ fontSize: "12px" }} className="mb-2 text-center pt-2">
                                            *{t("Select the correct amount on the machine after payment.")}
                                        </p>
                                    ) : null
                                }
                            </div>
                        )}

                        {STORETYPE === "cleanpro" && (
                            <div style={{ border: "5px solid #f2f2f2", marginTop: "20px" }}></div>
                        )}

                        {
                            STORETYPE === "cleanpro" && machine.notification && !machine.running ? (
                                <div>
                                    <div style={{ padding: "0px 5vw" }} className="payment-notification-container py-2">
                                        <p><b>{t("Select how you want to receive a notification when your laundry is finished")}:</b></p>
                                        <div className="d-flex justify-content-around form-group pt-1">
                                            <label htmlFor="radio2">
                                                <input type="radio" name="notificationType" id="radio2" value="Email" className="with-gap" checked={notificationType === "Email"} onChange={e => setNotificationType(e.target.value)} />
                                                <span style={{ color: "black" }}>{t("Email")}</span>
                                            </label>

                                            <label htmlFor="radio3">
                                                <input type="radio" name="notificationType" id="radio3" value="SMS" className="with-gap" checked={notificationType === "SMS"} onChange={e => setNotificationType(e.target.value)} />
                                                <span style={{ color: "black" }}>SMS ({getPaymentAmount("sms")} {t("credit")}/SMS)</span>
                                            </label>
                                        </div>

                                        {
                                            notificationType === "Email" ? (
                                                <div className="form-group">
                                                    <input type="email" id="email" className="form-control browser-default" value={email} onChange={e => setEmail(e.target.value)} required placeholder={t("Email Address")} />
                                                </div>
                                            ) : null
                                        }

                                        {
                                            notificationType === "SMS" ? (
                                                <div className="form-group">
                                                    <input type="tel" id="number" className="form-control browser-default" value={number} onChange={e => setNumber(e.target.value)} required placeholder={t("Phone Number")} />
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                    <div style={{ border: "5px solid #f2f2f2" }}></div>
                                </div>
                            ) : null
                        }

                        {STORETYPE === "cleanpro" ? (
                            <div className="pt-3" style={{ padding: "0px 5vw" }}>
                                {
                                    user && !machine.promo.status ? (
                                        <div>
                                            {
                                                (discountCode || !voucherId) && !laundryPass ? (
                                                    <div
                                                        id="cleanpro-input-discountcode"
                                                        onClick={(e) => setDiscountCodeModal(true)}
                                                        className="checkout-payment py-2"
                                                    >
                                                        <i className="bi bi-percent text-center" style={{ width: "32px" }}></i>
                                                        <p className="ml-1">
                                                            {discountCode ? discountCode : t("Discount Code")}
                                                        </p>
                                                        <p>
                                                            <i className="arrow right"></i>
                                                        </p>
                                                    </div>
                                                ) : null
                                            }

                                            {
                                                (voucherId || !discountCode) && !laundryPass ? (
                                                    <div
                                                        id="cleanpro-select-voucher"
                                                        onClick={(e) => setOpen(true)}
                                                        className="checkout-payment py-2"
                                                    >
                                                        <img
                                                            className="fit-contain"
                                                            style={{ width: "32px" }}
                                                            src={ticket}
                                                            alt=""
                                                        />
                                                        <p className="ml-1">
                                                            {voucherId ? t("1 voucher selected") : t("No voucher selected")}
                                                        </p>
                                                        <p>
                                                            <i className="arrow right"></i>
                                                        </p>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    ) : null
                                }
                                {
                                    user && laundryPassOption && !voucherId && !discountCode ? (
                                        <div id="cleanpro-point" className="d-flex justify-content-between py-2">
                                            <div className="d-flex align-items-center">
                                                <img style={{ width: "32px" }} src={voucherIcon} alt="" />
                                                <p className="ml-1">
                                                    {t("Laundry Pass")}
                                                </p>
                                            </div>
                                            <div className="toggle-switch">
                                                <input type="checkbox" className="toggle-switch-checkbox" name="laundrypass" id="laundrypass" checked={laundryPass} onChange={() => setLaundryPass(!laundryPass)} />
                                                <label className="toggle-switch-label" htmlFor="laundrypass">
                                                    <span className="toggle-switch-inner" />
                                                    <span className="toggle-switch-switch" />
                                                </label>
                                            </div>
                                        </div>
                                    ) : null
                                }
                                {
                                    user && user.loyalty_point ? (
                                        <div id="cleanpro-point" className="d-flex justify-content-between py-2">
                                            <div className="d-flex align-items-center">
                                                <img style={{ width: "32px" }} src={cleanproCreditIcon} alt="" />
                                                <p className="ml-1">
                                                    {user.point} {user.loyalty_point_name}
                                                </p>
                                            </div>
                                            <div className="toggle-switch">
                                                <input type="checkbox" className="toggle-switch-checkbox" name="point" id="point" checked={point} onChange={e => setPoint(!point)} />
                                                <label className="toggle-switch-label" htmlFor="point">
                                                    <span className="toggle-switch-inner" />
                                                    <span className="toggle-switch-switch" />
                                                </label>
                                            </div>
                                        </div>
                                    ) : null
                                }
                                {
                                    user && user.tokenSetting ? (
                                        <div
                                            id="cleanpro-reload-token"
                                            className="checkout-payment py-2"
                                        >
                                            <img style={{ width: "32px" }} src={creditBear} alt="" />
                                            <p className="ml-1">
                                                {user.token} {t("credits")}
                                            </p>
                                            <p>
                                                <i className="arrow right"></i>
                                            </p>
                                        </div>
                                    ) : null
                                }
                                {
                                    user ? <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div> : null
                                }
                            </div>
                        ) : (
                            <div className="list-group payment-list-group">
                                <div className="list-group-item payment-list-group-item">
                                    <div>
                                        <p className="text-secondary">{t("PRICE")}</p>
                                    </div>

                                    <div>
                                        <p>
                                            {currency(machine.country)} {/\./g.test(amount.toString()) ? parseFloat(amount).toFixed(2) : amount}
                                        </p>
                                    </div>
                                </div>

                                {user && !machine.promo.status ? (
                                    <div>
                                        {(discountCode || !voucherId) && !laundryPass ? (
                                            <div className="list-group-item payment-list-group-item">
                                                <div>
                                                    <p className="text-secondary">{t("DISCOUNT CODE")}</p>
                                                </div>

                                                <div>
                                                    <button
                                                        id="select-voucher"
                                                        type="button"
                                                        onClick={(e) => setDiscountCodeModal(true)}
                                                        className="text-secondary"
                                                        style={{
                                                            border: "none",
                                                            backgroundColor: "white",
                                                            outline: "none",
                                                            fontWeight: "bold",
                                                            paddingRight: "0px",
                                                            fontSize: "large",
                                                        }}
                                                    >
                                                        {discountCode && discountAmount
                                                            ? `${discountCode} (${discountText()})`
                                                            : t("Enter code >")}
                                                    </button>
                                                </div>
                                            </div>
                                        ) : null}

                                        {(voucherId || !discountCode) && !laundryPass ? (
                                            <div className="list-group-item payment-list-group-item">
                                                <div>
                                                    <p className="text-secondary">{t("VOUCHER")}</p>
                                                </div>

                                                <div>
                                                    <button
                                                        id="select-voucher"
                                                        type="button"
                                                        onClick={(e) => setOpen(true)}
                                                        className="text-secondary"
                                                        style={{
                                                            border: "none",
                                                            backgroundColor: "white",
                                                            outline: "none",
                                                            fontWeight: "bold",
                                                            paddingRight: "0px",
                                                            fontSize: "large",
                                                        }}
                                                    >
                                                        {voucherId ? discountText() : t("Select Voucher >")}
                                                    </button>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                ) : machine.promo.status && promotion ? (
                                    <div className="list-group-item payment-list-group-item">
                                        <div>
                                            <p className="text-secondary">{t("DISCOUNT")}</p>
                                        </div>

                                        <div>
                                            <p>{discountText()}</p>
                                        </div>
                                    </div>
                                ) : null}

                                {
                                    user && laundryPassOption && !voucherId && !discountCode ? (
                                        <div className="list-group-item payment-list-group-item align-items-center">
                                            <div>
                                                <p className="text-secondary">{t("Laundry Pass")}</p>
                                            </div>

                                            <div className="toggle-switch">
                                                <input type="checkbox" className="toggle-switch-checkbox" name="laundrypass" id="laundrypass" checked={laundryPass} onChange={e => setLaundryPass(!laundryPass)} />
                                                <label className="toggle-switch-label" htmlFor="laundrypass">
                                                    <span className="toggle-switch-inner" />
                                                    <span className="toggle-switch-switch" />
                                                </label>
                                            </div>
                                        </div>
                                    ) : null
                                }

                                {
                                    user && user.loyalty_point ? (
                                        <div className="list-group-item payment-list-group-item align-items-center">
                                            <div>
                                                <p className="text-secondary">{user.loyalty_point_name} ({user.point})</p>
                                            </div>

                                            <div className="toggle-switch">
                                                <input type="checkbox" className="toggle-switch-checkbox" name="point" id="point" checked={point} onChange={e => setPoint(!point)} />
                                                <label className="toggle-switch-label" htmlFor="point">
                                                    <span className="toggle-switch-inner" />
                                                    <span className="toggle-switch-switch" />
                                                </label>
                                            </div>
                                        </div>
                                    ) : null
                                }

                                {
                                    user && point ? (
                                        <div className="list-group-item payment-list-group-item">
                                            <div>
                                                <p className="text-secondary">{user.loyalty_point_name}</p>
                                            </div>

                                            <div>
                                                <p className="text-secondary">{getPaymentAmount("pointAmount")} {user.loyalty_point_name} ({currency(machine.country)} {getPaymentAmount("pointValue")})</p>
                                            </div>
                                        </div>
                                    ) : null
                                }

                                {
                                    notificationType === "SMS" ? (
                                        <div className="list-group-item payment-list-group-item">
                                            <div>
                                                <p className="text-secondary">SMS</p>
                                            </div>

                                            <div>
                                                <p>{currency(machine.country)} {getPaymentAmount("sms")}</p>
                                            </div>
                                        </div>
                                    ) : null
                                }

                                <div className="list-group-item payment-list-group-item">
                                    <div>
                                        <p
                                            style={{ fontSize: "24px", fontWeight: "bolder" }}
                                            className="text-secondary"
                                        >
                                            {t("TOTAL")}
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{ fontSize: "24px", fontWeight: "bolder" }}>
                                            {currency(machine.country)} {getPaymentAmount("totalPaymentAmount")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {STORETYPE === "cleanpro" && discountAmount && (
                            <div className="py-2 d-flex justify-content-between" style={{ padding: "0px 5vw" }}>
                                <h6> {t("Offers")}</h6>
                                <h6 style={{ color: "#333333" }}>-{discountText()}</h6>
                            </div>
                        )}

                        {STORETYPE === "cleanpro" && point && user && (
                            <div className="py-2 d-flex justify-content-between" style={{ padding: "0px 5vw" }}>
                                <h6>{user.loyalty_point_name}</h6>
                                <h6 style={{ color: "#333333" }}>-{getPaymentAmount("pointAmount")} {user.loyalty_point_name} ({!user.tokenSetting ? currency(machine.country) : ""} {getPaymentAmount("pointValue")} {user.tokenSetting ? t("credit") : ""})</h6>
                            </div>
                        )}

                        {STORETYPE === "cleanpro" && (
                            <div className="py-2 d-flex justify-content-between" style={{ padding: "0px 5vw" }}>
                                <h6>{t("Amount to Pay")}</h6>
                                <h6 className="font-weight-bold blue-t">
                                    {user && user.tokenSetting ? "" : currency(machine.country)} {getPaymentAmount("totalPaymentAmount")} {user && user.tokenSetting ? t("credits") : ""}
                                </h6>
                            </div>
                        )}

                        {
                            STORETYPE !== "cleanpro" && machine.notification && !machine.running ? (
                                <div className="px-3 pt-3">
                                    <h6><strong>{t("Notification")}</strong></h6>
                                    <p>{t("Do you want to receive notification when wash cycle done?")}</p>

                                    <div className="d-flex justify-content-around form-group">
                                        <label htmlFor="radio1">
                                            <input type="radio" name="notificationType" id="radio1" value="" className="with-gap" checked={notificationType === ""} onChange={e => setNotificationType(e.target.value)} />
                                            <span style={{ color: "black" }}>{t("No")}</span>
                                        </label>

                                        <label htmlFor="radio2">
                                            <input type="radio" name="notificationType" id="radio2" value="Email" className="with-gap" checked={notificationType === "Email"} onChange={e => setNotificationType(e.target.value)} />
                                            <span style={{ color: "black" }}>{t("Email")}</span>
                                        </label>

                                        <label htmlFor="radio3">
                                            <input type="radio" name="notificationType" id="radio3" value="SMS" className="with-gap" checked={notificationType === "SMS"} onChange={e => setNotificationType(e.target.value)} />
                                            <span style={{ color: "black" }}>SMS ({t("pay extra")} {currency(machine.country)}{getPaymentAmount("sms")})</span>
                                        </label>
                                    </div>

                                    {
                                        notificationType === "Email" ? (
                                            <div className="form-group">
                                                <label htmlFor="email">{t("Email Address")}:</label>
                                                <input type="email" id="email" className="form-control browser-default" value={email} onChange={e => setEmail(e.target.value)} required />
                                            </div>
                                        ) : null
                                    }

                                    {
                                        notificationType === "SMS" ? (
                                            <div className="form-group">
                                                <label htmlFor="number">{t("Phone Number")}:</label>
                                                <input type="tel" id="number" className="form-control browser-default" value={number} onChange={e => setNumber(e.target.value)} required />
                                            </div>
                                        ) : null
                                    }

                                </div>
                            ) : null
                        }

                        <div id="payment-button-container" className={STORETYPE === "cleanpro" ? "px-3 pt-3" : "mt-5 px-3"}>
                            {STORETYPE === "cleanpro" && (
                                <p style={{ fontSize: "12px" }} className="mb-2 text-center">
                                    *{machine.running ? t("Extended duration will be instantly reflected on machine after payment") : t("Please ensure that the machine door is shut & your laundry is inside.")}
                                </p>
                            )}
                            <button
                                id="pay"
                                onClick={(e) => handleSubmit(e)}
                                className="btn form-control big-button"
                            >
                                {STORETYPE === "cleanpro" ? t("Pay now") : t("PAY")}
                            </button>
                        </div>

                        {/* CUSTOM VOUCHER MODAL */}
                        {user && !machine.promo.status ? (
                            <div>
                                <div
                                    onClick={(e) => setOpen(false)}
                                    className={isOpen ? "custom-overlay isOpen" : "custom-overlay"}
                                ></div>
                                <div className={isOpen ? "custom-modal isOpen" : "custom-modal"}>
                                    <div className="custom-modal-header">
                                        {voucherId ? (
                                            <button
                                                id="remove-voucher"
                                                onClick={(e) => removeVoucher()}
                                                type="button"
                                                className="custom-modal-title m-0 p-0"
                                                style={{
                                                    color: "red",
                                                    border: "none",
                                                    backgroundColor: "white",
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                            >
                                                <i className="bi bi-slash-circle"></i> {t("Remove Voucher")}
                                            </button>
                                        ) : (
                                            <h6 className="custom-modal-title">
                                                {STORETYPE === "cleanpro" ? (
                                                    t("Select voucher to use")
                                                ) : (
                                                    <>
                                                        <i className="bi bi-tags-fill"></i> {t("Select Your Voucher")}
                                                    </>
                                                )}
                                            </h6>
                                        )}
                                    </div>

                                    <div className="custom-modal-body">
                                        <ul className="list-group list-group-flush px-4">
                                            {renderVouchers()}
                                        </ul>
                                    </div>
                                    {
                                        STORETYPE === "cleanpro" ? (
                                            <button id="use-voucher" onClick={useVoucher} className="btn big-button" style={{ width: "90%" }}>
                                                {t("Use voucher")}
                                            </button>
                                        ) : null
                                    }
                                </div>
                            </div>
                        ) : null}


                        <Modal
                            show={discountCodeModal}
                            onHide={(e) => setDiscountCodeModal(false)}
                            keyboard={false}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>{t("Enter Discount Code")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <form onSubmit={checkDiscountCode}>
                                        {discountCode ? (
                                            <div className="form-group text-left">
                                                <label htmlFor="code">{t("Discount Code")}</label>
                                                <input
                                                    type="text"
                                                    id="code"
                                                    name="code"
                                                    value={discountCode}
                                                    className="form-control browser-default"
                                                    readOnly
                                                />
                                            </div>
                                        ) : (
                                            <div className="form-group text-left">
                                                <label htmlFor="code">{t("Discount Code")}</label>
                                                <input
                                                    type="text"
                                                    id="code"
                                                    name="code"
                                                    value={discountCodeInput}
                                                    className="form-control browser-default"
                                                    onChange={(e) =>
                                                        setDiscountCodeInput(e.target.value.toUpperCase())
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}

                                        <div className="text-center pt-3">
                                            {loading ? (
                                                <div className="text-center">
                                                    <div
                                                        className={`spinner-border text-${STORETYPE === "cuci" ? "warning" : "primary"}`}
                                                        role="status"
                                                    >
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            ) : discountCode ? (
                                                <input
                                                    id="remove-discount-code"
                                                    type="button"
                                                    value={t("Remove Code")}
                                                    onClick={() => removeVoucher()}
                                                    className="btn btn-primary mx-2"
                                                />
                                            ) : (
                                                <input
                                                    id="submit-discount-code"
                                                    type="submit"
                                                    value={t("Submit")}
                                                    className="btn btn-primary mx-2"
                                                />
                                            )}
                                            <button
                                                id="close-discount-code"
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) => setDiscountCodeModal(false)}
                                            >
                                                {t("Close")}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal
                            show={insufficient}
                            onHide={(e) => setInsufficient(false)}
                            keyboard={false}
                            centered
                        >
                            <Modal.Body>
                                <div className={theme}>
                                    <img
                                        className="d-block"
                                        src={insufficientToken}
                                        style={{ width: "64px", margin: "0 auto" }}
                                        alt=""
                                    />
                                    <h2 className="align-center my-3"> {t("Insufficient balance")}</h2>
                                    <p className="text-center" style={{ fontSize: "16px", fontWeight: 300 }}>{t("Oops. You don't have enough balance to make the payment")}</p>
                                    <div className="d-flex justify-content-around pt-3">
                                        <button id="cleanpro-cancel-topup" onClick={() => setInsufficient(false)} style={{ height: "50px", width: "40%" }} className="btn secondary-button">
                                            {t("Back")}
                                        </button>
                                        <button id="cleanpro-topup-now" onClick={() => userLogout()} className="btn big-button" style={{ width: "50%" }}>
                                            {t("Topup now")}
                                        </button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </>)
            }
            {
                razerBody ? (
                    <div>
                        <form action={razerBody.paymentDomain} method="post">
                            <input type="hidden" name="amount" value={razerBody.amount} />
                            <input type="hidden" name="merchant_id" value={razerBody.merchant_id} />
                            <input type="hidden" name="orderid" value={razerBody.orderid} />
                            {razerBody.bill_name ? <input type="hidden" name="bill_name" value={razerBody.bill_name} /> : null}
                            {razerBody.bill_email ? <input type="hidden" name="bill_email" value={razerBody.bill_email} /> : null}
                            {razerBody.bill_mobile ? <input type="hidden" name="bill_mobile" value={razerBody.bill_mobile} /> : null}
                            <input type="hidden" name="bill_desc" value={razerBody.bill_desc} />
                            <input type="hidden" name="country" value={razerBody.country} />
                            <input type="hidden" name="vcode" value={razerBody.vcode} />
                            <input type="hidden" name="currency" value={razerBody.currency} />
                            <input type="hidden" name="returnurl" value={razerBody.returnurl} />
                            <input type="hidden" name="callbackurl" value={razerBody.callbackurl} />
                            <input type="hidden" name="cancelurl" value={razerBody.cancelurl} />

                            <input type="submit" name="submit" value="Pay with razer" hidden ref={razerButtonClick} />
                        </form>
                    </div>
                ) : null
            }
            {
                ghlBody ? (
                    <div>
                        <form name="frmPayment" method="post" action={ghlBody.frmPayment}>
                            <input type="hidden" name="TransactionType" value={ghlBody.TransactionType} />
                            <input type="hidden" name="PymtMethod" value={ghlBody.PymtMethod} />
                            <input type="hidden" name="ServiceID" value={ghlBody.ServiceID} />
                            <input type="hidden" name="PaymentID" value={ghlBody.PaymentID} />
                            <input type="hidden" name="OrderNumber" value={ghlBody.OrderNumber} />
                            <input type="hidden" name="PaymentDesc" value={ghlBody.PaymentDesc} />
                            <input type="hidden" name="MerchantReturnURL" value={ghlBody.MerchantReturnURL} />
                            <input type="hidden" name="Amount" value={ghlBody.Amount} />
                            <input type="hidden" name="CurrencyCode" value={ghlBody.CurrencyCode} />
                            <input type="hidden" name="CustIP" value={ghlBody.CustIP} />
                            <input type="hidden" name="CustName" value={ghlBody.CustName} />
                            <input type="hidden" name="CustEmail" value={ghlBody.CustEmail} />
                            <input type="hidden" name="CustPhone" value={ghlBody.CustPhone} />
                            <input type="hidden" name="HashValue" value={ghlBody.HashValue} />
                            <input type="hidden" name="PageTimeout" value={ghlBody.PageTimeout} />

                            <input type="submit" name="submit" value="Pay with GHL" hidden ref={ghlButtonClick} />
                        </form>

                    </div>
                ) : null
            }
        </div>
    )
}

export default DryerPayment;